.App {
  text-align: center;
  background-color: aqua;

  .myPage {
    margin: 10rem;
    padding: 15rem;
  }

}


